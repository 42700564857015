import "./UserProfileDlg.css";

import React, { useState } from "react";
import RegistrationService from "../Services/RegistrationService";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { StylesProvider } from "@material-ui/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, TextField, MenuItem } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { isEmpty, isNotEmpty } from "../utils/Validator.js";
import GlobalMessage from "./GlobalMessage.js";

window.analytics = { pageName: "User Demographics" };

const UserProfileDlg = ({ userName, open, onOk }) => {
  const [isOpen, setIsOpen] = useState(open);
  const [errorMessage, setErrorMessage] = useState(null);

  const [jobTitle, setJobTitle] = useState("");
  const [jobTitleError, setJobTitleError] = useState(null);

  const [companyName, setCompanyName] = useState("");
  const [companyNameError, setCompanyNameError] = useState(null);

  const [companyWebsite, setCompanyWebsite] = useState("");
  const [companyWebsiteError, setCompanyWebsiteError] = useState(null);

  const [providerType, setProviderType] = useState("");
  const [providerTypeError, setProviderTypeError] = useState(null);

  const [otherTypeDescription, setOtherTypeDescription] = useState("");
  const [otherTypeDescriptionError, setOtherTypeDescriptionError] =
    useState(null);

  const [providerTypeList] = useState([
    { value: "", label: "Select One" },
    { value: "OFC_STAFF", label: "Provider Office Staff" },
    { value: "BILLING_CO", label: "Billing Company" },
    { value: "OTHER", label: "Other" },
  ]);

  const clearErrors = () => {
    setErrorMessage(null);
    setJobTitleError(null);
    setCompanyNameError(null);
    setCompanyWebsiteError(null);
    setProviderTypeError(null);
    setOtherTypeDescriptionError(null);
  };

  const isURL = (str) => {
    try {
      new URL(str);
    } catch (error) {
      return false;
    }

    return true;
  };

  const isInvalidForm = () => {
    let hasErrors = false;

    if (isEmpty(jobTitle)) {
      setJobTitleError("Enter a job title.");
      hasErrors = true;
    }

    if (isEmpty(companyName)) {
      setCompanyNameError("Enter a company name.");
      hasErrors = true;
    }

    /*
    if (!isEmpty(companyWebsite)) {
      if (!isURL(companyWebsite)) {
        setCompanyWebsiteError("Enter a company website URL.");

        hasErrors = true;
      }
    }
    */

    if (isEmpty(providerType)) {
      setProviderTypeError("Select an account type.");
      hasErrors = true;
    }

    if (providerType === "OTHER" && isEmpty(otherTypeDescription)) {
      setOtherTypeDescriptionError("Enter account type description.");
      hasErrors = true;
    }

    return hasErrors;
  };

  const buildProfileForm = () => {
    let profileForm = {};

    profileForm.userNm = userName;
    profileForm.jobTitle = jobTitle;
    profileForm.companyNm = companyName;
    profileForm.companyUrl = companyWebsite;
    profileForm.affiliationCd = providerType;

    if (providerType === "OTHER") {
      profileForm.affiliationDesc = otherTypeDescription;
    }

    return profileForm;
  };

  const handleSubmit = () => {
    clearErrors();

    if (isInvalidForm()) {
      setErrorMessage("Please fix all errors.");
    } else {
      let profileForm = buildProfileForm();

      RegistrationService.updateProfile(profileForm)
        .then((response) => {
          setIsOpen(false);

          if (onOk) {
            onOk();
          }
      })
        .catch((error) => {
          console.log("Error updating User profile:" + error);
          console.error(error);

          // show cancel button if there was an error so user can continue?
          setErrorMessage("There was an error submitting your information.");
          // ignore the error and automatically continue after 3 seconds 
          // gives the user time to read error message
          setTimeout(() => {
            setIsOpen(false);

            if (onOk) {
              onOk();
            }
          }, 3000);
        });
    }
  };

  return (
    <>
      <StylesProvider injectFirst>
        <Dialog
          id="userProfileDlg"
          open={isOpen}
          onClose={() => {
            // prevent them from clicking off the dialog to close it
            console.log(`terminate dlg closing`);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>User Profile Information</DialogTitle>
          <DialogContent
            style={{ boxShadow: "0px 0px 0px 0px", width: "600px" }}
            elevation={0}
          >
            <div>
              <GlobalMessage message={errorMessage} />

              <p className="App-note-Field App-error-font App-error-color">
                Please enter the following profile information before
                continuing.
              </p>

              <div>
                <Grid container direction="column">
                  <Grid item style={{ width: "100%", textAlign: "left" }}>
                    <TextField
                      id="jobTitle"
                      name="jobTitle"
                      label="Job Title"
                      error={isNotEmpty(jobTitleError)}
                      helperText={jobTitleError}
                      value={jobTitle}
                      onChange={(e) => setJobTitle(e.target.value)}
                      inputProps={{ maxLength: 128 }}
                      fullWidth
                      required
                      autoFocus
                    />
                  </Grid>

                  <Grid item style={{ width: "100%", textAlign: "left" }}>
                    <TextField
                      id="companyName"
                      name="companyName"
                      label="Company Name"
                      error={isNotEmpty(companyNameError)}
                      helperText={companyNameError}
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      inputProps={{ maxLength: 128 }}
                      fullWidth
                      required
                    />
                  </Grid>

                  <Grid item style={{ width: "100%", textAlign: "left" }}>
                    <TextField
                      id="companyWebsite"
                      name="companyWebsite"
                      label="Company Website (URL)"
                      error={isNotEmpty(companyWebsiteError)}
                      helperText={companyWebsiteError}
                      value={companyWebsite}
                      onChange={(e) => setCompanyWebsite(e.target.value)}
                      inputProps={{ maxLength: 128 }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item style={{ width: "100%", textAlign: "left" }}>
                    <TextField
                      name="providerType"
                      id="providerType"
                      label="Provider Type"
                      error={isNotEmpty(providerTypeError)}
                      helperText={providerTypeError}
                      defaultValue="Select One"
                      select
                      value={providerType}
                      onChange={(e) => setProviderType(e.target.value)}
                      fullWidth
                      required
                    >
                      {providerTypeList.map((type) => (
                        <MenuItem
                          key={type.value}
                          value={type.value}
                          style={{ display: "block", paddingLeft: "1rem" }}
                        >
                          {type.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  {providerType === "OTHER" && (
                    <Grid item style={{ width: "100%", textAlign: "left" }}>
                      <TextField
                        id="otherTypeDescription"
                        name="otherTypeDescription"
                        label="Description of Other"
                        error={isNotEmpty(otherTypeDescriptionError)}
                        helperText={otherTypeDescriptionError}
                        value={otherTypeDescription}
                        onChange={(e) =>
                          setOtherTypeDescription(e.target.value)
                        }
                        inputProps={{ maxLength: 80 }}
                        fullWidth
                        required
                      />
                    </Grid>
                  )}
                </Grid>
              </div>

              <div style={{ textAlign: "right" }}>
                <Button variant="outlined" onClick={handleSubmit}>
                  Submit
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </StylesProvider>
    </>
  );
};

export default UserProfileDlg;

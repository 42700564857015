import axios from 'axios';
import {config} from "./ConfigService";

class ProviderService {

    getProviders(taxId) {
        //return axios.get(`${config.API_URL}/provider/list/${taxId}`)
        return axios.get(`${config.API_URL}/provider/list/${taxId}`)
    }

    getProviderPhysician(prvId) {
        return axios.get(`${config.API_URL}/provider/Phys/${prvId}`)
    }
    getProviderPhysicianSpecialties(prvId) {
        return axios.get(`${config.API_URL}/provider/Phys/Spec/${prvId}`)
    }
    getProviderPhysicianLocations(prvId) {
        return axios.get(`${config.API_URL}/provider/Phys/Loc/${prvId}`)
    }

    getProviderLicenses(prvId) {
        return axios.get(`${config.API_URL}/provider/Phys/Lic/${prvId}`)
    }

    getProviderPhysicianLicenses(prvId) {
        return axios.get(`${config.API_URL}/provider/Phys/Lic/${prvId}`)

    }
    getProviderFacility(prvId) {
        return axios.get(`${config.API_URL}/provider/facility/${prvId}`)
    }
    getProviderFacilityLocations(prvId) {
        return axios.get(`${config.API_URL}/provider/facility/Loc/${prvId}`)
    }
    getFacilityLicenses(prvId) {
        return axios.get(`${config.API_URL}/provider/Fac/Lic/${prvId}`)
    }

}

export default new ProviderService();

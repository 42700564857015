import React, {Component, Fragment} from 'react'
import AuthenticationService from '../Services/AuthenticationService'
import './LoginComponent.css';
import {NavLink} from "react-router-dom";
//import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import GlobalMessage from "./GlobalMessage";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
//import { toLowerCase } from '../utils/Utils.js';
import axios from 'axios';
import {isEmpty} from "../utils/Utils";
import {isNotEmpty} from "../utils/Validator";
import {config} from "../Services/ConfigService";

/*const INVALIDCREDENTIALS = "INVALID_CREDENTIALS";
const USERLOCKED = "USER_LOCKED";*/
window.analytics = {pageName: 'Login'};
window.analytics.user = {
    id:'',
    role:''
};


class LoginComponent extends Component {
    _isMounted = false;
    token = '';
    init = true;
    constructor(props) {
        super(props)
        let search = props.location.search;


        // const code = 'mycode';//new URLSearchParams(search).get('code');

        const code = new URLSearchParams(search).get('code');

        if(isEmpty(code) === true) {
            this.state = this.initializeState(this.getAuthMsg(new URLSearchParams(search).get('fail')));
            this.signInButtonRef = React.createRef();

        } else {

            if(this.init) {
                this.init = false;
                this.state ={code: code};
                this.authenticateUser();
            }
        }

    }
    getAuthMsg(fail) {
        let authenticationMessage ='';
        if (null != fail) {

            if (fail === 'USER_ACCT_DEACTIVATED') {
                authenticationMessage= 'Error: The account has been deactivated. Please use the Reset Password link below to reset your Password.';
            } else if (fail === 'USER_ACCT_INACTIVE') {
                authenticationMessage= 'Error: Please access the link in the Temporary Password Email or use the Reset Password link below to reset your Password.';
            } else if (fail.includes('USER_ACCT_LOCKED')) {
                const lockTime = this.getLockTime(fail);
                authenticationMessage= `Error: Your account has been temporarily locked for ${lockTime} minutes.  
                            Please use the Reset Password link below or contact your Account Administrator to reset your password. `;
            } else if (fail === 'USER_ACCT_TERMINATED') {
                authenticationMessage= 'Error: The account has been Terminated. Please contact your Account Administrator.';
            } else if (fail === 'INVALID_CREDENTIALS') {
                authenticationMessage= 'Error: The Username or Password is incorrect.';
            } else if (fail === 'USER_ACCT_UNKNOWN_STATUS') {
                authenticationMessage= 'Error: The Username or Password is incorrect.';
            } else if (fail === 'INACTIVE_CREDENTIALS') {
                authenticationMessage= 'Error: This account is not active. Access denied.';
            } else if(fail === 'MISSING_EMPLOYEE_ID') {
                authenticationMessage= 'Error: Your account is not associated with an Employee ID. Contact System Administrator.';
            }
        }
        return authenticationMessage;
    }

    initializeState(authMsg) {
        let URL=config.AEM_LOGINPAGE;
        /*if( window.location.href.startsWith("http://localhost")){
            //URL='http://localhost:4503/content/wcpp/wcpp_loginpage.html';
            URL='https://publish-p52919-e332331.adobeaemcloud.com/content/wcpp/wcpp_loginpage.html';
        }else if(window.location.href.startsWith("https://provider-dev")
            || window.location.href.startsWith("https://provider.coventry.uat.enlyte.com")
            || window.location.href.startsWith("https://coventryprovider-qa.mitchellcloud.com")){
            URL='https://aem-uat.coventryprovider.com/content/wcpp/wcpp_loginpage.html'
        }else if(window.location.href.startsWith("https://www.coventryprovider.com")){
            URL='https://aem.coventryprovider.com/content/wcpp/wcpp_loginpage.html'
        }*/
        const initialState = {
            username: '',  // use empty value, do not remove
            password: '',   // use empty value, do not remove
            hasLoginFailed: !isEmpty(authMsg),
            emptyUserNameMsg: '',
            emptyPasswordMsg: '',
            showValidError: false,
            authenticationMessage: authMsg,
            showLogoutMsg: this.props.timeOut(),
            isLoginButtonEnabled: true,
            aemURL:URL,
            showMessage:false

        };
        return initialState;
    }

    checkAEM = () => {
        axios.get(this.state.aemURL).then(response=>{
            if (this._isMounted) {
                this.setState({showMessage: true});
            }
        }).catch(error=> {
            if (this._isMounted) {
                this.setState({showMessage: false});
            }
        })
    }

    componentDidMount() {
        if(isEmpty(this.state.code)) {
            this._isMounted = true;
            this.checkAEM();
            window.addEventListener('message', this.myIframeEvent);

        }
    }

    componentWillUnmount(){
        this._isMounted = false;
        window.removeEventListener('message', this.myIframeEvent);
        if(window.loading) {
            window.loading = false;
        }
    }

    myIframeEvent = (event) => {
        // if (event.origin.startsWith('http://localhost:4503')
        //     || event.origin.startsWith('https://adrwa1904.cvty.com:5443')
        //     || event.origin.startsWith('https://aemcvtywcsqa.cvty.com')
        //     || event.origin.startsWith('https://coventrywcs.com')){
        //     if (event.data.hasOwnProperty("frameHeight")) {
        //         document.getElementById("wcppFrame").style.height = `${event.data.frameHeight}px`;
        //     }
        // } else {
        //     return;
        // }

         //let clientRect = null;
         let height = null;
         let wcppFrame = document.getElementById("wcppFrame");
         // console.log("message received in wcppIFrameEvent: " + event.data);
         if (wcppFrame == null) {
             console.log( "wcppFrame is null !! " );
             return;
         }

        //console.log( "wcppFrame is not null !! " );

        if (event.data) {
 
            try {
                if(isNotEmpty(event.data.height) ){
                    console.log( "event.data.height : " + event.data.height );
                    height = `${event.data.height}px`;

                    wcppFrame.style.minHeight = height;
                    wcppFrame.style.height = height;
                }

                //clientRect = JSON.parse(event.data);
                // add to rect height to account for horizontal scrollbar height
                // if ((clientRect !== null) && clientRect.hasOwnProperty("height")) {
                //     height = `${clientRect.height + 17}px`;
                // }

                // wcppFrame.style.minHeight = height;
                // wcppFrame.style.height = height;

            } catch (e) {
                console.log("Error parsing clientRect: " + event.data, e);
                console.log(event.data);
            }
                
        } else {
            console.log( "event.data is null " );
            return;
        }
     
        //IE 11 specific, need to remove when IE 11 is no longer supported
        if(document.getElementById("curpwdId")){
            document.getElementById("curpwdId").style['-ms-user-select'] = 'text';
            document.getElementById("curpwdId").focus();
            document.getElementById("curpwdId").style['-ms-user-select'] = '';
        }
        //End of IE 11 specific
    }


    handleChange = (event) => {
        this.setState(
            {
                [event.target.name]: event.target.value
            }
        )
    }
    displayLoginError = () => {

        if (this.state.hasLoginFailed || this.state.showValidError) {
            return <GlobalMessage type='error' message={this.state.authenticationMessage}/>;
        }

    }


    validate = () => {
        this.setState({hasLoginFailed: false, emptyUserNameMsg: '', emptyPasswordMsg: '', showLogoutMsg: false,
            isLoginButtonEnabled:false});

        let isValid = true;
        if (this.state.username === '') {
            this.setState({emptyUserNameMsg: `Error: Enter Username`});

            isValid = false;
        }
        if (this.state.password.length < 1) {
            this.setState({emptyPasswordMsg: `Error: Enter Password`})
            isValid = false;
        }
        if (isValid) {
//  console.log('login to be called pswrd->' + this.state.emptyPasswordMsg + '  user->' +this.state.emptyUserNameMsg);
            this.setState({showValidError: false})
            this.loginClicked();
        } else {
            this.setState({showValidError: true})
            this.setState({authenticationMessage: `An error has occurred. Please address all fields marked with “Error”. `})
            this.setState({
                isLoginButtonEnabled:true
            });
        }

    }
    authenticateUser = () => {
        if(!window.login || window.login === false) {
            window.login= true;
            return;
        }

        AuthenticationService
            .executeJwtAuthenticationService('checkAuth', this.state.code)
            .then((response) => {

                AuthenticationService.registerSuccessfulLoginForJwt(response.data.username, response.data.token);

                this.loadUserThenGoHome(response.data.token);


            }).catch((error, code) => {

            const link = document.createElement('a');
            link.href=`/wcpp/login?fail=${error.response.data}`;
            link.click();

        })
    }

    loadUserThenGoHome = (token) => {

        AuthenticationService.getUsrDtls(AuthenticationService.getLoggedInUserName())
            .then((response) => {
                this.props.setUser(response.data, token);
                const userDetailsInfo = response.data;
                let user = {};
                user.id = userDetailsInfo.id;
                user.role = userDetailsInfo.typeCd === 'PRUS' ? 'Sub User' : 'Admin User';
                window.analytics.user = { ...user, ...userDetailsInfo};
                this.props.history.push(`/wcpp/home`);
                window.login = false;
            }).catch(function (error) {
            window.login = false;
            console.log('login getting usr dtl error:' + error);
            this.props.history.push(`/wcpp/login`);
        })
    }

    loginClicked = () => {

        AuthenticationService
            .executeJwtAuthenticationService(this.state.username, this.state.password)
            .then((response) => {
                AuthenticationService.registerSuccessfulLoginForJwt(this.state.username, response.data.token)
                this.setState({isLoginButtonEnabled: false})
                this.loadUserThenGoHome(response.data.token);
            }).catch((error) => {
            if (null != error.response) {
                this.setState({hasLoginFailed: true,isLoginButtonEnabled:true})
                if (null != error.response.data) {
                    if (error.response.data === 'USER_ACCT_DEACTIVATED') {
                        this.setState({authenticationMessage: 'Error: The account has been deactivated. Please use the Reset Password link below to reset your Password.'})
                    } else if (error.response.data === 'USER_ACCT_INACTIVE') {
                        this.setState({authenticationMessage: 'Error: Please access the link in the Temporary Password Email or use the Reset Password link below to reset your Password.'})
                    } else if (error.response.data.includes('USER_ACCT_LOCKED')) {
                        const lockTime = this.getLockTime(error.response.data);
                        this.setState({authenticationMessage: `Error: Your account has been temporarily locked for ${lockTime} minutes.  
                            Please use the Reset Password link below or contact your Account Administrator to reset your password. `})
                    } else if (error.response.data === 'USER_ACCT_TERMINATED') {
                        this.setState({authenticationMessage: 'Error: The account has been Terminated. Please contact your Account Administrator.'})
                    } else if (error.response.data === 'INVALID_CREDENTIALS') {
                        this.setState({authenticationMessage: 'Error: The Username or Password is incorrect.'})
                    } else if (error.response.data === 'USER_ACCT_UNKNOWN_STATUS') {
                        this.setState({authenticationMessage: 'Error: The Username or Password is incorrect.'})
                    } else if (error.response.data === 'INACTIVE_CREDENTIALS') {
                        this.setState({authenticationMessage: 'Error: This account is not active. Access denied.'})
                    }else if (error.response.data === 'MISSING_EMPLOYEE_ID') {
                        this.setState({authenticationMessage: 'Error: Your account is not associated with an Employee ID. Contact System Administrator.'})
                    }
                }
            }
        })
    }

    getLockTime = (message) =>{
        const number = message.substring('USER_ACCT_LOCKED:'.length)

        if(number.trim() !== '') {
            return number;
        } else return 30;
    }


    // Fired if user clicks on it or presses enter on it or spacebar
    handleSignInOnClick= async (e) => {
		
        const link = document.createElement('a');
		link.href=config.EAS_URL;
      
        link.click();
    }

    // Do not login if Enter key was pressed on the signInButton because
    // it will also a get a click event and we'll sign in with that one
    checkForFormSubmissionKey = (e) => {
        if(this.state.isLoginButtonEnabled){
            if ((e.key === 'Enter' && (e.target !== this.signInButtonRef.current))
                || (e.key ===' ' && (e.target === this.signInButtonRef.current))) {
                this.setState({ isLoginButtonEnabled: false });
                this.validate();
            }
        }
    }

    render() {
        if(isNotEmpty(this.state.code)) {

            return (<Fragment/>);
        }

        const messageExists = this.state.showMessage;
        let frameContent='';
        if(messageExists){
            frameContent =  <div className="iframe-container">
                <iframe title="wcppFrame" name="wcppFrame" id="wcppFrame"
                        src={this.state.aemURL}
                        scrolling="no"
                ></iframe>
            </div>;
        }

        return (
            <Fragment>


                <div className='Login-view'>
                    {frameContent}
                    <div className='Login-div' onKeyPress={(e) => this.checkForFormSubmissionKey(e)}>

                        <div className='Login-content'>


                            <h1 style={{
                                fontFamily: '"Arial-Bold", Arial', fontWeight: 700,
                                fontStyle: 'normal', fontSize: '16px', paddingTop: '1rem', paddingBottom: '.6rem'
                            }}>
                                Click sign in to access the Coventry Provider Portal</h1>
                            <div className=' timeout-msg' hidden={this.state.showLogoutMsg === false}>

                                <ErrorOutlineIcon style={{color:'#ffffff',verticalAlign:'1rem', paddingRight:'.25rem',display:'inline-block'}}/>
                                <div style={{display:'inline-block'}}>
                                    <p>You have been signed out due to 30 minutes of inactivity.</p>
                                    <p>Please sign in again.</p></div>
                            </div>
                            <div hidden={this.state.showLogoutMsg === false} style={{paddingTop:'1rem'}}/>

                            {this.displayLoginError()}


                            <Button style={{marginLeft: 0, marginTop: '1rem'}}
                                    type="button"
                                    disabled={!this.state.isLoginButtonEnabled}
                                    ref={this.signInButtonRef}
                                    onClick={this.handleSignInOnClick}
                                    aria-label='Sign In: SIGN IN Button'>SIGN IN
                            </Button>
                            <div style={{
                                float: 'right',
                                display: 'inline-block',
                                paddingRight: '1rem',
                                fontSize: '13px',
                                paddingTop: '1.75rem',
                                paddingBottom: '1rem'
                            }}>

                                <NavLink className='App-link' to='/wcpp/forgotusernm' aria-label='Sign In: Forgot Username? Hyperlink'>Forgot Username?</NavLink>

                                <span style={{paddingLeft: '.2em', paddingRight: 0, color: '#0f197d'}}>|</span><span
                                style={{paddingLeft: '.2em'}}><NavLink className='App-link' to='/wcpp/resetPassword' aria-label='Sign In: Reset Password Hyperlink'>Reset Password</NavLink></span>
                            </div>
                        </div>
                        <div className='App-font'
                             style={{backgroundColor: '#F2F2F2', textAlign: 'center', padding: '.75rem'}}>
                            <NavLink className='App-link' to='/wcpp/registration' aria-label='Sign In: Register Here Hyperlink'>Register Here</NavLink>
                        </div>
                    </div>

                </div>


            </Fragment>
        )
    }
}

export default LoginComponent;
import React, {Component, Fragment} from 'react'
import {withStyles} from "@material-ui/core/styles";
import './ProviderResources.css'
import ProviderResourcesTable from '../ProviderResourcesTable'
//import axios from 'axios';
import AuthenticationService from "../../Services/AuthenticationService";


const styles = theme => ({
    root: {
        width: '100%',
    },
    button: {
        margin: theme.spacing.unit,    
        cursor: 'pointer',
        color: '#0f197d'
    },
    input: {
      display: 'none',
    },
    
});
window.analytics ={pageName:'Provider Resources'};
class ProviderResources extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            resources: [],filteredUsers: [],
            errorMsg: '',         
            successmessage: ''
        }

    }

    setfilter = (filtered) => {
        console.log(`in fitler set state`)
        this.setState({filteredUsers: filtered})

    }

    componentDidMount() {
        this.getUser()
    }

   
    getUser = ()=> {
        let currentComp = this;
          //AuthenticationService.getProviderResources()
          //http://localhost:3000/aem/api/assets/wcpp/provider-resources/provider-resources.json
          //http://localhost:3000/provider-resources.json
          fetch('/aem/api/assets/wcpp/provider-resources/provider-resources.json',
            {
                headers : { 
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
                 }
              }
          )
            .then((response) => {
                if(response.status === 200) {
                    console.log(response);
                    return response.json();
                    // if(response.data !== 'undefined' && response.data.entities !== 'undefined'){
                    //     currentComp.setState({resources: response.data.entities, filteredUsers: response.data.entities})                    
                    // }
                }              
            })
            .then((jsn)=>{
                if(jsn !== 'undefined' && jsn.entities !== 'undefined'){
                    currentComp.setState({resources: jsn.entities, filteredUsers: jsn.entities})                    
                } 
            })
            .catch(error => {
                console.log("Error in fetching: provider-resources.json ");
                console.log(error); 
        })
    }

    render() {
               
        return(
          
            <Fragment>

                <div className='App-content Provider-resources '>
                    <div><h1 className='App-page-label' style={{display:'inline-block'}}>Provider Resources</h1>
                      </div>                      
                    <div className='App-error App-font' aria-live='polite'>{this.state.errorMsg}</div>
                    <div className='App-success App-font' aria-live='polite'>{this.state.successmessage}</div> 
                    <div style={{paddingTop:'.5rem', fontSize: '13px'}}>   
                    <a className='App-link' 
                           href='https://www.coventrywcs.com'
                           aria-label="Opens Coventry Workers' Comp website in a new window" target="_blank" rel="noopener noreferrer">
                           Visit the Coventry Workers' Comp website </a> for extra information about Coventry.</div>             
                   <ProviderResourcesTable resources={this.state.resources} filteredUsers={this.state.filteredUsers} setFilter={this.setfilter}
                                    />
                </div>
            </Fragment>

        );

    }
}
export default withStyles(styles)(ProviderResources);
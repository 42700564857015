import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {  Button, TextField, MenuItem, withStyles} from "@material-ui/core";
import AuthenticationService from "../../Services/AuthenticationService.js";
import './UserProfile.css';
import AddUserTINTable from "./AddUserTINTable.js";

import { isNotEmpty,  isAlpha, isEmailAddress, validatePhone, hasLength, isName, isEmpty} from '../../utils/Validator.js';
import LoadingMsgBar from "../LoadingMsgBar.js";
import GlobalMessage from "../GlobalMessage.js";
import AddUserAddTINDialog from './AddUserAddTINDialog.js';
import AddUserGroupTINSTable from './AddUserGroupTINSTable.js';

const styles = () => ({
    root: {
        width: '100%',
    },

});
window.analytics ={pageName:'Add User'};
class AddUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
           userDetailsInfo: null, firstName: '',  lastName: '',  middleInitial : '', phoneNumber : '', isPhoneNumValid : true, 
           emailAddress : '', enlyteEmpId: '',
           securityQuestionList : [],  secQuestionId : 1,  accountTypeList : [], accountType:'Select One',
           valid: true, securityQuestion : '',
           progMsg:'',successMsg: '', errorMsg: '', loadingMessage: '',
           isNextEnabled: true, affiliation:'Select One', affiliationList : [],
           showTinDlg: false, showDlgOkCancel: false, dlgMsg: '', tin:'', selectedTINs:[], showTINTable: false, providersTINs:[],  providersFilteredTINs:[],selectedPrv: [],
           groupAssignment:'new', tins: [], filteredTins: [], grpId: '',isMirror:false, selected:[], loadTinsTable: false, showGroupTINTable: false, 
           jobTitle:'', companyName:'', companyUrl:'', selectedGroupTINS:[], showTextFields:false, showGroupId:true
        }
        
        this.handleDialogData = this.handleDialogData.bind(this);
        this.usrfirstnameInput = React.createRef();
    };

    setProvidersTINs = (prov) => {
       this.setState(prevState => ({
        providersTINs: [...prevState.providersTINs, prov]
      }))
            
    };
    

    setProvidersFilteredTINs = (prov) => {
        this.setState(prevState => ({
            providersFilteredTINs: [...prevState.providersFilteredTINs, prov]
          }))
                
    }

    setFilterTins = (filtered) => {
        console.log( `in fitler set state`)
        if(filtered && filtered.length > 0){
            this.setState({filteredTins:filtered});
        }
        else{
            this.setState({filteredTins: []});
        }

    }
    addErrorMessage(fieldName, errorMessage) {
        this.setState({
            [fieldName]: errorMessage
        });
    }

    clearErrorMessage(fieldName) {
        this.setState({
            [fieldName]: ''
        });
    }

    // save any changes the user makes into the state variable so you can
    // get back to the state the user was in
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checkecd : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleAccountTypeChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checkecd : target.value;
        const name = target.name;
        this.handleReset();
        this.setState({
            [name]: value
        });
        if(value !== 'Select One'){
            this.setState({showTextFields:true});
        }
        else{
           this.setState({showTextFields:false}); 
        }
        
      };

   /*  showLoadingIndicator(shouldShow) {
        this.setState({ loadingMessage: shouldShow === true ? `Please wait while we validate the data.` : '' });
    }
 */
    clearErrorMessages() {
        const errorFieldList = [
           
            'firstNameError',
            'lastNameError',
            'middleInitialError',
            'phoneNumberError',        
            'emailAddressError',
            'enlyteEmpIdError',
            'accountTypeError',
           'grpIdError',
           'affiliationError',
           'jobTitleError',
           'companyNameError'
        ];

        errorFieldList.forEach(errorFieldName => {
            this.clearErrorMessage(errorFieldName);
        });
    }

    
   
    getFormData() {
        const plainPhoneNm = this.state.phoneNumber.replace(/[^\d]/g, "");
        let selectedFtins=[];
       if(((this.state.accountType === 'PRAD') && (this.state.groupAssignment === 'new'))){           
            if (isNotEmpty(this.state.providersTINs)) {
                this.state.providersTINs.forEach(provTIN => {                 
                    selectedFtins.push({
                        ftin: provTIN.ftin,
                    });
                });
            }
            console.log(selectedFtins);
           // userFtins:[{"ftin":"160743029"},{"ftin":"231352213"}]
        }

        if(((this.state.accountType === 'PRUS'))){           
            if (isNotEmpty(this.state.selectedGroupTINS)) {
                this.state.selectedGroupTINS.forEach(selTIN => {                 
                    selectedFtins.push({
                        ftin: selTIN,
                    });
                });
            }
            console.log(selectedFtins);
           // userFtins:[{"ftin":"160743029"},{"ftin":"231352213"}]
        }

        return {
            userFirstName: this.state.firstName.trim(),
            userLastName: this.state.lastName.trim(),
            userMidName: this.state.middleInitial.trim(),
            emailAddr: this.state.emailAddress.trim(),
            phoneNum: plainPhoneNm,
            typeCd: this.state.accountType.trim(),
            //grpInfoId: this.state.userDetailsInfo.grpInfoId,
            grpInfoId: this.state.grpId,
            mgrUserInfoId: this.state.userDetailsInfo.id,
            statusDesc: this.state.userDetailsInfo.userNm,
            //userFtins:[{"ftin":"160743029"},{"ftin":"231352213"}],
            userFtins:selectedFtins,
            employeeId: ((this.state.enlyteEmpId === "") ? null : this.state.enlyteEmpId),
            affiliationCd: ((this.state.affiliation === "Select One") ? '' : this.state.affiliation),
	        affiliationDesc: this.state.other,
	        jobTitle: this.state.jobTitle,
	        companyNm: this.state.companyName,
	        companyUrl:this.state.companyUrl ,
        }
    }

     handleReset = () => {
        this.clearErrorMessages();
        this.setState({
             firstName: '',  lastName: '',  middleInitial : '', phoneNumber : '', isPhoneNumValid : true,
             emailAddress : '', enlyteEmpId: '', accountType:'', affiliation:'Select One',
             valid: true, securityQuestion : '',
             progMsg:'',successMsg: '', errorMsg: '', loadingMessage: '',
             isNextEnabled: true, 
             affiliationList : [],
             showTinDlg: false, showDlgOkCancel: false, dlgMsg: '', tin:'', selectedTINs:[], showTINTable: false, providersTINs:[],  providersFilteredTINs:[],selectedPrv: [],
             groupAssignment:'new', tins: [], filteredTins: [], grpId: '',isMirror:false, selected:[], loadTinsTable: false, showGroupTINTable: false,
             jobTitle:'', companyName:'', companyUrl:'', selectedGroupTINS:[], showTextFields:false, showGroupId:true
         });
         this.loadAccountTypes();
         this.loadAffiliationList();
         this.setState({accountType: 'Select One'});
         this.setState({affiliation: 'Select One'});

     }

     setSelectedGroupTINS = (selected) => {
        this.setState({selectedGroupTINS: selected})

    }

     handleCreate = () => {
        console.log(`create new user ===>${JSON.stringify(this.getFormData())}`)
         const newUserDetails = this.getFormData();
         AuthenticationService.createNewUser(newUserDetails)
             .then(response => {
                 if(response.status === 200) {
                     this.handleReset();
                     this.setState({successMsg: 'User has been successfully created!'})
                 }


             }).catch((error) => {
                if(null!= error.response){
                    if(null!=error.response.data){
                        if(error.response.data.message === 'INVALID_ENLYTE_EMPLOYEE_ID'){
                            this.setState({ errorMsg: `Error: An error has occurred. Please try again or contact Customer Service.`}); 
                            this.addErrorMessage("enlyteEmpIdError","Error: Enter a valid Enlyte Employee ID");
                        }
                    }
                }
             console.log(`update user from settings ${error.message}`);
             this.setState({ errorMsg: `Error: An error has occurred. Please try again or contact Customer Service.`});
             this.setState({isNextEnabled:true})
             console.log(`update user ${error}`);
         })

     }

     handleNext = () => {
        this.setState({errorMsg: '',successMsg: ''});
       const isValidSyntax = this.doSyntaxValidation();
        
       if(isValidSyntax){
            this.setState({isNextEnabled: false})
           const emailaddr = this.state.emailAddress;
           AuthenticationService.checkEmailAddressExists(emailaddr).then((response) => {
            const emailExist = response.data;
            if(emailExist){
                this.setState({isNextEnabled: true})
                this.setState({errorMsg: `An error has occurred. Please address all fields marked with “Error”.`, emailAddressError: `Error: The Email Address is associated to another account`});
               }else{             
                    this.handleCreate();   
               }
           
        }, (error) => {
            console.log(error);
            this.setState({progMsg:'', errorMsg: `Error: An error has occurred. Please try again or contact Customer Service.`})
        });
     }
     else{
        this.setState({errorMsg: `An error has occurred. Please address all fields marked with “Error”.`});
        }  
    }
    
    handleOnBlurPhoneNum=(event) =>  {
        const changed = event.target.value;
        this.setState({phoneNumber : changed});
        if(changed.length < 10){
            
            this.setState({valid:false});
        }
        else{
            const phone = validatePhone(changed)
            if(phone.length < 10){
                
                this.setState({valid:false});
            }
            else
            {
                this.setState({phoneNumber : phone});
                this.setState({isPhoneNumValid:true, valid:true, phoneNumberError:''});
            }
        }
    }

    doSyntaxValidation() {
        let isValid = true;
        this.clearErrorMessages();

        if(this.state.accountType === 'Select One'){
            this.addErrorMessage("accountTypeError","Error: Select Account Type");
            return false;
        }

        if (!isName(this.state.firstName) || !hasLength(this.state.firstName, 1, 40)) {
            this.addErrorMessage("firstNameError", "Error: Enter valid First Name" );
            isValid = false;
        }

        if (!isName(this.state.lastName) || !hasLength(this.state.lastName, 1, 40)) {
            this.addErrorMessage("lastNameError","Error: Enter valid Last Name");
            isValid = false;
        }

        if (isNotEmpty(this.state.middleInitial) && !isAlpha(this.state.middleInitial)) {
            this.addErrorMessage("middleInitialError","Error: Enter Middle Initial" );
            isValid = false;
        }
        if(((this.state.accountType === 'PRAD') && (this.state.groupAssignment === 'existing')) || (this.state.accountType === 'PRUS'))  {
            if(isEmpty(this.state.grpId)){
                this.addErrorMessage("grpIdError","Error: Enter a valid group Id" );
                    isValid = false;
            }
            if (isNotEmpty(this.state.grpId) && isAlpha(this.state.grpId)) {
                    this.addErrorMessage("grpIdError","Error: Enter a valid group Id" );
                    isValid = false; 
            }
        }

        if ( validatePhone (this.state.phoneNumber) === '') {
            this.addErrorMessage("phoneNumberError", "Error: Enter a valid Phone Number");
            isValid = false;
        }
        if(((this.state.accountType === 'ISAD') || (this.state.accountType === 'IRAD'))) {
                if (isEmpty(this.state.enlyteEmpId) ) {
                    this.addErrorMessage("enlyteEmpIdError","Error: Enter a valid Enlyte Employee ID");
                    isValid = false;
                }
        }
      
        if (!isEmailAddress(this.state.emailAddress)) {
            this.addErrorMessage("emailAddressError","Error: Enter a valid Email Address");
            isValid = false;
        }
     
        if((this.state.accountType === 'PRAD') || (this.state.accountType === 'PRUS'))  {
            if(this.state.affiliation === 'Select One'){
                this.addErrorMessage("affiliationError","Error: Select Affiliation");
                isValid = false; 
                }
            if(isEmpty(this.state.jobTitle)){
                this.addErrorMessage("jobTitleError","Error: Enter a valid Job Title");
                isValid = false; 
            }

            if(isEmpty(this.state.companyName)){
                this.addErrorMessage("companyNameError","Error: Enter a valid Company Name");
                isValid = false; 
                }
        }
        if((this.state.accountType === 'PRUS'))  {
            if(isEmpty(this.state.selectedGroupTINS)){
                this.addErrorMessage("grpIdError","Error: Select TIN" );
                isValid = false; 
            }
        }  
        return isValid;

    }

    getUser = ()=> {

        AuthenticationService.getUsrDtls(AuthenticationService.getLoggedInUserName())
            .then((response) => {
                this.setState({userDetailsInfo: response.data});
                console.log(`header user dtls'===>${JSON.stringify(this.state.userDetailsInfo)}`);
            }).catch(function (error) {
            console.log('header getting usr dtl error:' + error);
        })
        return true
    }

    loadAccountTypes() {
        const accountTypList = [];
        //let typeList = null;

            accountTypList.push({
                value: 'IRAD',
                label: 'Internal Read-Only Admin'
            });
            accountTypList.push({
                value: 'ISAD',
                label: 'Internal System Admin'
            });
            accountTypList.push({
                value: 'PRAD',
                label: 'Provider Admin'
            });
            accountTypList.push({
                value: 'PRUS',
                label: 'Provider User'
            });
            this.setState({ accountTypeList: accountTypList });
            return;
        
      /*   AuthenticationService.getUserAccountTypes().then((response) => {

            typeList = response.data;
            
            if (isNotEmpty(typeList)) {

                typeList.forEach(type => {
                    
                    accountTypList.push({
                        value: type.userTypeCd,
                        label: type.userTypeDesc
                    });
                   
                  
                });
            }

            this.setState({ accountTypeList: accountTypList });
            
        }, (error) => {            
            console.log(error)
            this.setState({progMsg:'', errorMsg: `Error: An error has occurred. Please try again or contact Customer Service.`})
            this.setState({ accountTypeList: accountTypList });
        }); */
    }

    loadAffiliationList() {
        const affilList = [];
            affilList.push({
                value: 'OFC_STAFF',
                label: 'Provider Office Staff'
            });
            affilList.push({
                value: 'BILLING_CO',
                label: 'Billing Company'
            });
            affilList.push({
                value: 'OTHER',
                label: 'Other'
            });
           
            this.setState({ affiliationList: affilList });        
        
    }
    

    componentDidMount() { 
        this.loadAccountTypes();
        this.loadAffiliationList();
        this.setState({accountType: 'Select One'});
        this.setState({affiliation: 'Select One'});
        this.getUser();
        this.setState({isNextEnabled: true})
      
    };

    showSuccessErrorMessage = () =>{
        return(
            <>
                <LoadingMsgBar loadMsg={this.state.loadingMessage}/>
                <GlobalMessage  message={this.state.errorMsg} />
                <GlobalMessage type ='success' message={this.state.successMsg} />

            <div className='App-note-Field App-error-font App-error-color'>
                Note: Fields marked with an asterisk(*) are required.
            </div></>
        );
    }
    showButtons =() =>{
       
            return(
            <nav>
                <Button disabled={!this.state.isNextEnabled} onClick={this.handleNext}>CREATE USER</Button>
                <Button variant="outlined" onClick={this.handleReset}>RESET</Button>
            </nav>
            );
      
    }

    setFilter = (filtered) => {
        if(filtered && filtered.length > 0){
            this.setState({providersFilteredTINs:filtered});
        }else{
            this.setState({providersFilteredTINs:[]});
        }

    };

    handleDialogData(taxId, prvId, prvName) {
        this.addTINS(taxId, prvId, prvName);
      }

      addTINS(taxId, prvId, prvName){    
        const exists = this.state.providersTINs.some(prov => prov.ftin === taxId);   
        if(exists){
            this.setErrorTINMsg();
        }else{
            let tableData = {};
            tableData.prvName = prvName;
            tableData.prvId = prvId;
            tableData.ftin = taxId;
            tableData.selected = false;
            this.addProviderTIN(tableData);
            this.setState({showTINTable:true});
        }
      }

      deleteRow = (index) => {
        this.setState((prevState) => {
          const newItems = [...prevState.providersTINs];
          newItems.splice(index, 1);
          return { providersTINs: newItems, providersFilteredTINs: newItems };
        });
      }


    addProviderTIN = (newProv) => {
       this.setProvidersTINs(newProv);
       this.setProvidersFilteredTINs(newProv);
      };

   
   
    handleAddTaxId = () => {        
        this.setState({ showTinDlg: true});
    }

    
    closeMsgDlg = (event, reason) => {
        console.log(this.state.data);
        if (reason !== 'backdropClick') {
           // setOpen(false);
          
        this.setState({ showTinDlg: false});
        }
    }

  
    handleGroupAssignment = event => {
        this.setState({groupAssignment: event.target.value})
    }

    getTable = () => {    
        if (this.state.providersTINs.length > 0) {
            console.log("getting table");
            return (
              
            <AddUserTINTable rows={this.state.providersTINs} filtered={this.state.providersFilteredTINs} setFilter={this.setFilter}  
                deleteRow= {this.deleteRow } />                   
                      
            );
        }
    };

    getGroupTINSTable = () => {        
            
        if ((this.state.tins.length > 0 ) && (this.state.loadTinsTable)) {
            console.log("getting table");
            return (                        
                <AddUserGroupTINSTable tinsFiltered={this.state.filteredTins} setFilter={this.setFilterTins}
                tins={this.state.tins} setSelected={this.setSelectedGroupTINS}
                selected={this.state.selectedGroupTINS} admin={false}/>
     
            );
        }
    
    };

    handleGroupTinAdd = () => {
        if(isNotEmpty(this.state.grpId) && !isAlpha(this.state.grpId)){
            this.setState({showGroupId:false, selectedGroupTINS:[]});
           this.loadTable();
        }else{
                this.addErrorMessage("grpIdError","Error: Enter a valid group Id" );
               
            }
        
    }

    handleClearGroupId  = () => {
        this.setState({grpId:'', showGroupId:true, selectedGroupTINS:[], tins: [], filteredTins: []});
    }

    loadTable = () => {
        //1804
        this.clearErrorMessages();
        this.setState({tins: [], filteredTins: []});
        AuthenticationService.getFtinsForUser(this.state.grpId)
            .then((response) => {
                const tins= []
                if(isEmpty(response.data)){
                    this.addErrorMessage("grpIdError","Error: Enter a valid group Id" );
                }else{
                response.data.forEach(tin =>{
                  const date = new Date(tin.createdDate)
                    tin.displayDate =  `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`
                    tins.push(tin)
                })
                this.setState({tins: tins, filteredTins: tins, loadTinsTable:true});
            }
            }).catch(error => {
            this.setErrorMsg()
            this.setState({loadTinsTable:false});
            console.log('Error receiving user ftins:' + error);
        })
    }

    setErrorMsg = () => {
        this.setState({progMsg:'', errorMsg: `Error:  An error has occurred.  Please try again or contact Customer Service.`})
    }

    setErrorTINMsg = () => {
        this.setState({progMsg:'', errorMsg: `You already added this TIN`})
    }

    
    render() {

        return(
            
            <Fragment>

               <div>
                   <h1 className='App-page-label'>Add User </h1>

                   {this.showSuccessErrorMessage()}

                <div className="step-main">
                <Grid container direction="column">  
                
                    <Grid item style={{width:'50%', textAlign:'left'}}>
                            <TextField name="accountType" id="accountType" label="Account Type"
                                error={isNotEmpty(this.state.accountTypeError)} helperText={this.state.accountTypeError}
                                defaultValue="Select One"
                                select
                                value={this.state.accountType}
                                onChange={this.handleAccountTypeChange}
                                fullWidth
                                required
                            >
                                 <MenuItem key={'Select One'} value={'Select One'}>
                                        {'Select One'}
                                    </MenuItem>
                                {this.state.accountTypeList.map(type => (
                                    <MenuItem key={type.value} value={type.value}>
                                        {type.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {(this.state.showTextFields) ? <>
                        {(this.state.accountType === 'PRAD') 
                        ? 
                        <Grid item style={{width:'50%', textAlign:'left'}}>
                            <FormLabel required component="legend">Group Assignment</FormLabel>
                            <RadioGroup aria-label="position" defaultValue='new' name="position"
                                        onChange={this.handleGroupAssignment}  ref={this.radioButtonRef} row>
                                <FormControlLabel
                                    value='new'
                                    control={<Radio color="primary" />}
                                    label="New"
                                    labelPlacement="end"
                                    name='New'
                                />
                                <FormControlLabel
                                    name='Existing'
                                    value='existing'
                                    control={<Radio color="primary" />}
                                    label="Existing"
                                    labelPlacement="end"

                                />
                            </RadioGroup>    
                                </Grid>:''
                            }
                        
                    { (((this.state.accountType === 'PRAD') && (this.state.groupAssignment === 'existing')) || (this.state.accountType === 'PRUS'))  
                     ? 
                        <Grid item style={{width:'50%', textAlign:'left'}}>
                     
                         <TextField id="grpId" name="grpId" label="Group Id"
                             error={isNotEmpty(this.state.grpIdError)} helperText={this.state.grpIdError}   
                             value={this.state.grpId}
                             disabled={!this.state.showGroupId}
                             onChange={this.handleInputChange}
                             inputProps={{ maxLength: 11 }}
                             fullWidth
                             required
                         />
                     </Grid>:''}

                     {((this.state.accountType === 'PRAD') && (this.state.groupAssignment === 'new') )
                     ? 
                     <Grid item style={{width:'50%', textAlign:'left'}}>
                        <FormLabel >Tax Id</FormLabel>
                        <Button variant="outlined" onClick={this.handleAddTaxId} >Add</Button>
                        {this.getTable()}
                     </Grid>
                    : null}

                    {((this.state.accountType === 'PRUS') )
                    ? 
                    <Grid item style={{width:'50%', textAlign:'left'}}>
                        <FormLabel >Tax Id</FormLabel>
                  
                        <Button variant="outlined" onClick={this.handleGroupTinAdd}>Add</Button>
                        <Button variant="outlined" onClick={this.handleClearGroupId} >Clear Group Id</Button>                            
                            {this.getGroupTINSTable()}
                   </Grid>
                   : null}
                 
                    { (((this.state.accountType === 'PRAD') || (this.state.accountType === 'PRUS'))) ? <>
                     <Grid item style={{width:'50%', textAlign:'left'}}>
                            <TextField name="affiliation" id="affiliation" label="Affiliation"
                                error={isNotEmpty(this.state.affiliationError)} helperText={this.state.affiliationError}
                                defaultValue="Select One"
                                select
                                value={this.state.affiliation}
                                onChange={this.handleInputChange}
                                fullWidth
                                required
                                
                            >
                                 <MenuItem key={'Select One'} value={'Select One'}>
                                        {'Select One'}
                                    </MenuItem>
                                {this.state.affiliationList.map(type => (
                                    <MenuItem key={type.value} value={type.value}>
                                        {type.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {(isNotEmpty(this.state.affiliation) && (this.state.affiliation === 'OTHER') ) ? 
                        
                    <Grid item style={{width:'50%', textAlign:'left'}}>
                         <TextField id="other" name="other" label="Other"
                             error={isNotEmpty(this.state.firstNameError)} helperText={this.state.firstNameError}   
                             value={this.state.other}
                             onChange={this.handleInputChange}
                             inputProps={{ maxLength: 40 }}
                             fullWidth
                             required
                         /> 
                        </Grid> : null}</>
                        : ''}
                      
                       
                         <Grid item style={{width:'50%', textAlign:'left'}}>
                         <TextField id="firstName" name="firstName" label="First Name"
                             error={isNotEmpty(this.state.firstNameError)} helperText={this.state.firstNameError}   
                             value={this.state.firstName}
                             onChange={this.handleInputChange}
                             inputProps={{ maxLength: 40 }}
                             fullWidth
                             required
                         />
                     </Grid>

                     <Grid item style={{width:'50%', textAlign:'left'}}>
                         <TextField id="lastName" name="lastName" label="Last Name"
                             error={isNotEmpty(this.state.lastNameError)} helperText={this.state.lastNameError}
                             value={this.state.lastName}
                             onChange={this.handleInputChange}
                             inputProps={{ maxLength: 40 }}
                             fullWidth
                             required
                         />
                     </Grid>

                     <Grid item style={{width:'50%', textAlign:'left'}}>
                         <TextField id="middleInitial" name="middleInitial" label="Middle Initial"
                             error={isNotEmpty(this.state.middleInitialError)} helperText={this.state.middleInitialError}
                             value={this.state.middleInitial}
                             onChange={this.handleInputChange}
                             inputProps={{ maxLength: 1 }}
                             fullWidth
                         />
                     </Grid>

                     <Grid item style={{width:'50%', textAlign:'left'}}>
                         <TextField id="phoneNumber" name="phoneNumber" label="Phone Number"
                             error={isNotEmpty(this.state.phoneNumberError)} helperText={this.state.phoneNumberError}
                             value={this.state.phoneNumber}
                             onChange={this.handleInputChange} onBlur={this.handleOnBlurPhoneNum}
                             inputProps={{ maxLength: 15 }}
                             fullWidth
                             required
                         />
                     </Grid>

                     <Grid item style={{width:'50%', textAlign:'left'}}>
                         <TextField id="emailAddress" name="emailAddress" label="Email Address"
                             error={isNotEmpty(this.state.emailAddressError)} helperText={this.state.emailAddressError}
                             value={this.state.emailAddress}
                             onChange={this.handleInputChange}
                             inputProps={{ maxLength: 120 }}
                             fullWidth
                             required
                         />
                     </Grid>
                   
                     { (((this.state.accountType === 'PRAD') || (this.state.accountType === 'PRUS'))) ? <> 
                     <Grid item style={{width:'50%', textAlign:'left'}}>
                         <TextField id="jobTitle" name="jobTitle" label="Job Title"
                          error={isNotEmpty(this.state.jobTitleError)} helperText={this.state.jobTitleError}
                             value={this.state.jobTitle}
                             onChange={this.handleInputChange}
                             inputProps={{ maxLength: 120 }}
                             fullWidth
                             required
                         />
                     </Grid>
                     <Grid item style={{width:'50%', textAlign:'left'}}>
                         <TextField id="companyName" name="companyName" label="Company Name"
                          error={isNotEmpty(this.state.companyNameError)} helperText={this.state.companyNameError}
                             value={this.state.companyName}
                             onChange={this.handleInputChange}
                             inputProps={{ maxLength: 120 }}
                             fullWidth
                             required
                         />
                     </Grid>
                     <Grid item style={{width:'50%', textAlign:'left'}}>
                         <TextField id="companyUrl" name="companyUrl" label="Company Url"
                             value={this.state.companyUrl}
                             onChange={this.handleInputChange}
                             inputProps={{ maxLength: 120 }}
                             fullWidth
                         />
                     </Grid>
                     </> : ''}
                    
                     {(((this.state.accountType === 'ISAD') || (this.state.accountType === 'IRAD'))) ?
                     <Grid item style={{width:'50%', textAlign:'left'}}>
                         <TextField id="enlyteEmpId" name="enlyteEmpId" label="Enlyte Employee ID"
                             error={isNotEmpty(this.state.enlyteEmpIdError)} helperText={this.state.enlyteEmpIdError}
                             value={this.state.enlyteEmpId}
                             onChange={this.handleInputChange}
                             inputProps={{ maxLength: 120 }}
                             fullWidth
                             required
                         />
                     </Grid> : null} 
                     </> :''}
                 </Grid>
            </div>
   
 
               {this.showButtons()}
                </div>
                <AddUserAddTINDialog  open={this.state.showTinDlg} onClose={this.closeMsgDlg} onDataReceived={this.handleDialogData}/>
            </Fragment>
         
        );

    }
}
export default withStyles(styles)(AddUser);